// React
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react'

// Supabase
import { Session } from '@supabase/supabase-js'

// Types
import { Subscription, UserDetails } from '@supermeme-ai/types'

// Utils
import { supabaseBrowserClient } from '@/utils/supabase-browser-client'

// External
import useSWR from 'swr'
import axios from 'axios'

const fetcher = (url: string, token: string) =>
  axios.get(url, { headers: { token } }).then((res) => res.data)

export const UserContext = createContext<{
  session: Session | null
  userDetails: UserDetails | null
  setUserDetails: (userDetails: UserDetails) => void
  remainingCredits: number
  subscription: Subscription | null
  watermark: string
  setWatermark: (watermark: string) => void
  refreshSession: () => void
  refreshUsedCredits: () => Promise<void>
}>(null)

export function UserContextProvider({ children }: { children: JSX.Element }) {
  const [session, setSession] = useState<Session | null>(null)
  const [refreshSessionKey, setRefreshSessionKey] = useState(0)

  useEffect(() => {
    const getSession = async () => {
      const {
        data: { session },
      } = await supabaseBrowserClient.auth.getSession()
      setSession(session)
    }
    getSession()
  }, [refreshSessionKey])

  const { data: userDetailsData, mutate: mutateUserDetails } = useSWR(
    session ? ['/api/account/user-details', session.access_token] : null,
    async ([url, token]) => {
      try {
        return await fetcher(url, token)
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Ugly hack to manually delete the auth token cookie
          // Supabase auth token cookies are not being deleted properly on the client side when the session is expired
          localStorage.removeItem('supabase.auth.token')
          const cookies = document.cookie.split(';')
          for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            if (cookie.startsWith('sb-') && cookie.includes('-auth-token')) {
              const cookieName = cookie.split('=')[0]
              document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
            }
          }
          window.location.href = '/login'
        }
        throw error
      }
    }
  )

  const { data: usedCreditsData, mutate: mutateUsedCredits } = useSWR(
    session && userDetailsData
      ? ['/api/account/used-credits', session.access_token]
      : null,
    ([url, token]) => fetcher(url, token)
  )

  const userDetails = userDetailsData?.userDetails
  const subscription = userDetails?.subscription
  const usedCredits = usedCreditsData?.usedCreditCount || 0

  const remainingCredits = (() => {
    if (!userDetails) return 0
    const totalCredits =
      subscription?.prices?.products.metadata?.limitedMonthlyCredits === 'true'
        ? parseInt(
            subscription?.prices?.products.metadata?.monthlyCredits as string
          ) || 100
        : userDetails.credits
    return Math.max(0, totalCredits - usedCredits)
  })()

  const [watermark, setWatermark] = useState<string>('Supermeme.ai')

  useEffect(() => {
    if (userDetails) {
      if (!userDetails.subscription) {
        setWatermark('Supermeme.ai')
      } else if (
        userDetails.subscription?.prices?.products?.metadata
          ?.customWatermark === 'true'
      ) {
        setWatermark(userDetails.watermark_text || '')
      } else {
        setWatermark('')
      }
    }
  }, [userDetails])

  const refreshSession = useCallback(
    () => setRefreshSessionKey((prev) => prev + 1),
    []
  )

  const refreshUsedCredits = useCallback(async () => {
    if (session?.access_token) {
      await mutateUsedCredits()
    }
  }, [session, mutateUsedCredits])

  const value = {
    session,
    userDetails,
    setUserDetails: (newUserDetails: UserDetails) =>
      mutateUserDetails({ userDetails: newUserDetails }),
    remainingCredits,
    subscription,
    watermark,
    setWatermark,
    refreshSession,
    refreshUsedCredits,
  }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUserDetails = (): [
  UserDetails,
  (userDetails: UserDetails) => void,
] => {
  const context = useContext(UserContext)
  return [context.userDetails, context.setUserDetails]
}

export const useSession = (): Session => useContext(UserContext).session

export const useSubscription = (): Subscription =>
  useContext(UserContext).subscription

export const useRemainingCredits = (): number =>
  useContext(UserContext).remainingCredits

export const useWatermark = (): [string, (watermark: string) => void] => {
  const context = useContext(UserContext)
  return [context.watermark, context.setWatermark]
}

export const useRefreshSession = (): (() => void) =>
  useContext(UserContext).refreshSession

export const useRefreshUsedCredits = (): (() => Promise<void>) =>
  useContext(UserContext).refreshUsedCredits
