import { emailRegex } from '@supermeme-ai/utils'
import { encode } from 'js-base64'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export const extractDomainFromEmail = (email: string): string | null => {
  if (emailRegex.test(String(email).toLowerCase())) {
    const domain = email.split('@')[1]
    return domain
  }
  return null
}

export const toBase64FromFile = (file: Blob): Promise<any> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export const toBase64 = (str: string): string =>
  typeof window === 'undefined'
    ? Buffer.from(str).toString('base64')
    : encode(str)

export const splitCaption = (
  caption: string
): {
  top: string
  bottom: string
} => {
  if (!caption) {
    return {
      top: '',
      bottom: '',
    }
  }
  const wordsArray = caption.split(' ')
  return {
    top: wordsArray.slice(0, wordsArray.length / 2).join(' '),
    bottom: wordsArray.slice(wordsArray.length / 2).join(' '),
  }
}

export const stripDataURLFromB64 = (base64String: string): string =>
  base64String.split(',')[1]

export const getFileExtensionFromB64 = (base64String: string): string =>
  base64String.split(';')[0].split('/')[1]

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
