import { useRouter } from 'next/router'

export const LANDING_ROUTES = [
  '/',
  '/contact',
  '/gif-memes',
  '/imgflip-alternative',
  '/influencer-memes',
  '/kapwing-alternative',
  '/meme-api',
  '/meme-creators',
  '/meme-creators/chief-meme-officer',
  // '/l/meme-generator', TODO: rethink this when making meme-generator public
  // '/meme-generator',
  '/meme-generator/choosing-a-meme-generator',
  '/meme-generator/choosing-the-right-font-for-memes',
  '/meme-generator/top-10-features-to-look-for-in-a-meme-generator',
  '/meme-maker/creating-memes',
  '/meme-maker/how-to-make-memes-for-your-community',
  '/meme-marketing',
  '/meme-marketing/are-memes-copyrighted',
  '/meme-marketing/meme-marketing-platform',
  '/meme-templates',
  '/meme-templates/choosing-meme-templates',
  '/meme-templates/clean-meme-templates-for-business',
  '/meme-templates/how-to-find-meme-templates-without-captions',
  '/memes',
  '/memes/customer-service-memes',
  '/memes/marketing-memes',
  '/memes/meetings-memes',
  '/memes/memes-about-memes',
  '/memes/memes-about-work',
  '/memes/memes-for-coworkers',
  '/memes/product-manager-memes',
  '/memes/sales-memes',
  '/memes/thursday-work-memes',
  '/memes/travel-memes',
  '/memes/zoom-meetings-memes',
  '/pitch-deck',
  '/pricing',
  '/privacy-policy',
  '/search-supported-languages',
  '/teaching-with-memes',
  '/teaching-with-memes/how-to-use-memes-in-presentations',
  '/team',
  '/terms-and-conditions',
]
export type LandingRoute = (typeof LANDING_ROUTES)[number]

export const useLayout = () => {
  const { pathname } = useRouter()
  return LANDING_ROUTES.indexOf(pathname) > -1 ? 'landing' : 'main'
}
