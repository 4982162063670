import Script from 'next/script'
import React from 'react'

const GoogleAnalytics: React.FC = () => {
  return process.env.NODE_ENV === 'production' ? (
    <>
      <Script
        id='google-analytics-1'
        async
        src='https://www.googletagmanager.com/gtag/js?id=G-VP61MN2DDR'
      />
      <Script
        id='google-analytics-2'
        strategy='lazyOnload'
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-VP61MN2DDR');`,
        }}
      />
    </>
  ) : (
    <></>
  )
}

export default GoogleAnalytics
