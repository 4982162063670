import Script from 'next/script'
import React from 'react'

const Usetiful: React.FC = () => {
  return (
    <>
      <Script
        id='Usetiful'
        strategy='lazyOnload'
        dangerouslySetInnerHTML={{
          __html: `
          (function (w, d, s) {
            var a = d.getElementsByTagName('head')[0];
                var r = d.createElement('script');
                r.async = 1;
                r.src = s;
                r.setAttribute('id', 'usetifulScript');
                r.dataset.token = "ac5c190d2a5ccb0cecbd78ba0668b634";
                a.appendChild(r);
          })(window, document, "https://www.usetiful.com/dist/usetiful.js");`,
        }}
      />
    </>
  )
}

export default Usetiful
