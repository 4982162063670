import React from 'react'
import Head from 'next/head'
import Link from 'next/link'
import { Button } from '@/components/ui/button'
import { ArrowPathIcon } from '@heroicons/react/24/outline'
import { LifeBuoyIcon } from 'lucide-react'

const ErrorComponent: React.FC = () => {
  return (
    <>
      <Head>
        <title>Error | Supermeme.ai</title>
      </Head>
      <div className='flex h-[70vh] flex-col items-center justify-center'>
        <h2 className='mb-8 text-center text-2xl font-bold'>
          Uh-oh! There&apos;s an unexpected error. Please try again!
        </h2>
        <div className='mt-10 flex items-center justify-center space-x-6'>
          <button
            className='primary-button-solid'
            type='button'
            onClick={() => {
              window.location.reload()
            }}
          >
            <ArrowPathIcon className='h-5 w-5' />
            <span>Refresh</span>
          </button>
          <Button variant='default' asChild>
            <Link href='/contact'>
              <LifeBuoyIcon className='mr-2 h-5 w-5' />
              <span>Contact Support</span>
            </Link>
          </Button>
        </div>
      </div>
    </>
  )
}

export default ErrorComponent
